<template>
  <div class="bind">
    <van-nav-bar title="用户身份绑定" />
    <div class="logo">
      <img src="./../../assets/img/icon1.png" alt="" />
    </div>
    <van-form validate-first @failed="onFailed" style="" :show-error="false">
      <van-field
        v-model="username"
        name="validator"
        placeholder="用户名"
        label="用户名"
        :rules="[{ validator, message: '请输入用户名' }]"
      />
      <van-field
        v-model="password"
        name="validator"
        label="密码"
        placeholder="密码"
        type="password"
        :rules="[{ validator, message: '请输入密码' }]"
      />
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="bindUser"
        >
          身份绑定
        </van-button>
      </div>
    </van-form>
    <div class="bottom">
      <img src="./../../assets/img/icon.png" alt="" />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      code: "",
      username: "",
      password: "",
    };
  },
  mounted() {
    // this.getCode();
    this.getUser();
  },
  methods: {
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      if (val.trim() === "") {
        return false;
      }
      return true;
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        Toast.loading("验证中...");

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    getCode() {
      // 非静默授权，第一次有弹框
      const local = window.location.href; // 获取页面url
      const appid = "wxa19cd448cf77a531";
      this.code = this.getUrlCode().code; // 截取code
      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      } else {
        // 你自己的业务逻辑
      }
    },
    getUrlCode() {
      // 截取url中的code方法
      const url = location.search;
      this.winUrl = url;
      const theRequest = {};
      if (url.indexOf("?") != -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    bindUser() {
      Toast.loading({
        message: "绑定中...",
        forbidClick: true,
      });

      const data = {
        code: this.code,
        account: this.username,
        password: this.$md5(this.password),
      };

      this.$api.common.bindUser(data).then((res) => {
        if (res.data.state === 0 && res.data.data.token) {
          Toast.success("绑定成功");
          sessionStorage.setItem("username", this.username);
          sessionStorage.setItem("password", this.password);
          localStorage.setItem("token", res.data.data.token);
          // 1 环保局用户 2企业用户 3运维用户
          localStorage.setItem("userType", res.data.data.userType);
          // 1超级管理员 2领导 3执法人员
          localStorage.setItem("roleId", res.data.data.roleId);
          localStorage.setItem("userId", res.data.data.userId);
          if (res.data.data.enterpriseId) {
            localStorage.setItem("enterpriseId", res.data.data.enterpriseId);
          }
          if (localStorage.getItem("userType") == 1) {
            if (localStorage.getItem("roleId") == 2) {
              // 领导跳转boss页面
              this.$router.push({
                path: "/boss",
              });
            } else {
              // 普通执法人员跳转index页面
              this.$router.push({
                path: "/index",
              });
            }
          } else {
            // 企业用户或运维用户跳转event页面
            this.$router.push({
              path: "/event",
            });
          }
        } else {
          Toast.success("绑定失败");
        }
      });
    },
    getUser() {
      this.username = sessionStorage.getItem("username");
      this.password = sessionStorage.getItem("password");
    },
  },
};
</script>

<style></style>
